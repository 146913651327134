import {compose} from "redux";
import NavBar from "../components/NavBar";
import * as React from "react";
import {Utils} from "../utils/Utils";
import Jquery from "../assets/site/jquery.svg";
import HTML from "../assets/site/html-5.svg";
import css from "../assets/site/css-3.svg";
import boorstrap from "../assets/site/bootstrap-4.svg";
import mysql from '../assets/site/mysql.svg'
import graph from '../assets/site/graphql.svg'
import angular from '../assets/site/angular-icon-1.svg'
import magento from '../assets/site/magento-2.svg'
import woo from '../assets/site/woocommerce.svg'
import open from '../assets/site/opencart.svg'

interface Props {
    history: {
        push: (path: string, state: any) => any
    }
}

class EDevelopment extends React.Component<Props, {}>{
    utils = new Utils();
    componentDidMount(): void {
        this.utils.LoadScripts();
    }

    componentWillUnmount(): void {
        this.utils.UnloadScripts();
    }
    render(): React.ReactNode {
        return (
            <React.Fragment>
                <div id="wrapper" className="single-page-wrap">
                    <div className="content">
                        <div className="single-page-decor"></div>
                        <div className="single-page-fixed-row">
                            <div className="scroll-down-wrap">
                                <div className="mousey">
                                    <div className="scroller"></div>
                                </div>
                                <span>Scroll Down</span>
                            </div>
                            <a onClick={() => this.props.history.push('/', {})} className="single-page-fixed-row-link"><i
                                className="fal fa-arrow-left"></i> <span>Back to home</span></a>
                        </div>
                        <section className="dark-bg no-padding">
                            <div className="hidden-info-wrap-bg">
                                <div className="bg-ser">
                                </div>
                                <div className="overlay"></div>
                            </div>
                            <div className="hidden-info-wrap">
                                <div className="hidden-info fl-wrap">
                                    <div className="hidden-info-title">E - Commerce Development</div>
                                    <div className="hidden-works-list fl-wrap">
                                        <div className="hidden-works-item  serv-works-item fl-wrap"
                                             data-bgscr="images/bg/long/1.jpg">
                                            <div className="hidden-works-item-text">
                                                <h3>E - Commerce Development</h3>
                                                <ul className="fl-wrap">
                                                    <li><a href="#">Concept > </a></li>
                                                    <li><a href="#">Design > </a></li>
                                                    <li><a href="#">Code > </a></li>
                                                    <li><a href="#">Production</a></li>
                                                </ul>
                                                <p>We pride ourselves in building, redesigning or migrating successful ecommerce stores. We have developed ecommerce shops for start-ups to big brands. We consider various strategic, technical and marketing aspects while creating an online store. We offer best suitable solution as per your requirement to create ecommerce website.</p>
                                                <div className="clearfix"></div>
                                                <div className="serv-price">STARTING FROM 700$</div>
                                                <span className="serv-number">01.</span>
                                                <div className="serv-icon"><i className="fal fa-desktop"></i></div>
                                            </div>
                                        </div>
                                        <div className="hidden-works-item  serv-works-item fl-wrap"
                                             data-bgscr="images/bg/long/1.jpg">
                                            <div className="hidden-works-item-text">
                                                <h3>Online Shopping made easy</h3>
                                                <ul className="fl-wrap">
                                                    <li><a href="#">Logo</a></li>
                                                    <li><a href="#">Graphics</a></li>
                                                    <li><a href="#">SEO</a></li>
                                                </ul>
                                                <p>For creating custom websites we work with Bootstrap, HTML5, PHP/CakePHP, MySQL, AJAX, Jquery. We shape your ecommerce business on open source platforms like Magento, Open Cart and Woo Commerce.</p>
                                                <div className="clearfix"></div>
                                                <div className="serv-price">STARTING FROM 200$</div>
                                                <span className="serv-number">03.</span>
                                                <div className="serv-icon"><i className="fab fa-pagelines"></i></div>
                                            </div>
                                        </div>
                                        <div className="hidden-works-item  serv-works-item fl-wrap"
                                             data-bgscr="images/bg/long/1.jpg">
                                            <div className="hidden-works-item-text">
                                                <h3>Technologies we work with</h3>
                                                <ul className="fl-wrap">
                                                    <li><a href="#"><img src={Jquery} style={{height: 20}}/></a></li>
                                                    <li><a href="#"><img src={HTML} style={{height: 30}}/></a></li>
                                                    <li><a href="#"><img src={css} style={{height: 30}}/></a></li>
                                                    <li><a href="#"><img src={boorstrap} style={{height: 30}}/></a></li>
                                                    <li><a href="#"><img src={mysql} style={{height: 30}}/></a></li>
                                                    <li><a href="#"><img src={graph} style={{height: 30}}/></a></li>
                                                    <li><a href="#"><img src={angular} style={{height: 30}}/></a></li>
                                                    <li><a href="#"><img src={magento} style={{height: 30}}/></a></li>
                                                    <li><a href="#"><img src={woo} style={{height: 30}}/></a></li>
                                                    <li><a href="#"><img src={open} style={{height: 30}}/></a></li>
                                                </ul>
                                                <p>We use latest technologies that makes next generation web and process faster. Following are the technologies that we use to create magnificent web pages.</p>
                                                <div className="clearfix"></div>
                                                <div className="serv-price">CONTACT US</div>
                                                <span className="serv-number">04.</span>
                                                <div className="serv-icon"><i className="fal fa-shopping-bag"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="fl-wrap limit-box"></div>
                        </section>
                        <section className="dark-bg2 small-padding order-wrap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h3>Ready To order Your Project ?</h3>
                                    </div>
                                    <div className="col-md-4"><a href="contscts.html"
                                                                 className="btn flat-btn color-btn">Get In Touch</a>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="height-emulator fl-wrap"></div>
                </div>
            </React.Fragment>
        )
    }
}

export default compose<any>(NavBar)(EDevelopment)

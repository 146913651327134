import {compose} from "redux";
import NavBar from "../components/NavBar";
import * as React from "react";
import {Utils} from "../utils/Utils";
import Jquery from '../assets/site/jquery.svg'
import HTML from '../assets/site/html-5.svg'
import css from '../assets/site/css-3.svg'
import boorstrap from '../assets/site/bootstrap-4.svg'
import {withRouter} from "react-router";

interface Props {
    history: {
        push: (path: string, state: any) => any
    }
}

class WbDevelopment extends React.Component<Props, {}>{
    utils = new Utils();
    componentDidMount(): void {
        this.utils.LoadScripts();
    }

    componentWillUnmount(): void {
        this.utils.UnloadScripts();
    }

    render(): React.ReactNode {
        return (
            <React.Fragment>
                <div id="wrapper" className="single-page-wrap">
                    <div className="content">
                        <div className="single-page-decor"></div>
                        <div className="single-page-fixed-row">
                            <div className="scroll-down-wrap">
                                <div className="mousey">
                                    <div className="scroller"></div>
                                </div>
                                <span>Scroll Down</span>
                            </div>
                            <a onClick={() => this.props.history.push('/', {})} className="single-page-fixed-row-link"><i
                                className="fal fa-arrow-left"></i> <span>Back to home</span></a>
                        </div>
                        <section className="dark-bg no-padding">
                            <div className="hidden-info-wrap-bg">
                                <div className="bg-ser">
                                </div>
                                <div className="overlay"></div>
                            </div>
                            <div className="hidden-info-wrap">
                                <div className="hidden-info fl-wrap">
                                    <div className="hidden-info-title">Responsive Web Design</div>
                                    <div className="hidden-works-list fl-wrap">
                                        <div className="hidden-works-item  serv-works-item fl-wrap"
                                             data-bgscr="images/bg/long/1.jpg">
                                            <div className="hidden-works-item-text">
                                                <h3>Responsive Web Design</h3>
                                                <ul className="fl-wrap">
                                                    <li><a href="#">Concept > </a></li>
                                                    <li><a href="#">Design > </a></li>
                                                    <li><a href="#">Code > </a></li>
                                                    <li><a href="#">Production</a></li>
                                                </ul>
                                                <p>We will help you to build the visual identity of your product with a sophisticated and fully-responsive website that perform in all device. We believe design has the power to make the world a better place. We have an established approach for building a website to make sure you get exactly what you want. We start by understanding your business and create website which can highlight features of your product or services. This simple yet practical approach helps customer engagement and increase conversions.</p>
                                                <div className="clearfix"></div>
                                                <div className="serv-price">STARTING FROM 250$</div>
                                                <span className="serv-number">01.</span>
                                                <div className="serv-icon"><i className="fal fa-desktop"></i></div>
                                            </div>
                                        </div>
                                        <div className="hidden-works-item  serv-works-item fl-wrap"
                                             data-bgscr="images/bg/long/1.jpg">
                                            <div className="hidden-works-item-text">
                                                <h3>UI/UX Design</h3>
                                                <ul className="fl-wrap">
                                                    <li><a href="#">Awesome Designs</a></li>
                                                    <li><a href="#">Responsiveness</a></li>
                                                    <li><a href="#">Blueprints</a></li>
                                                </ul>
                                                <p>We build websites with easy user interface and great user experience, smart enough to resize its content as per screen size of different devices.</p>
                                                <div className="clearfix"></div>
                                                <div className="serv-price">STARTING FROM 170$</div>
                                                <span className="serv-number">02.</span>
                                                <div className="serv-icon"><i className="fal fa-mobile-android"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="hidden-works-item  serv-works-item fl-wrap"
                                             data-bgscr="images/bg/long/1.jpg">
                                            <div className="hidden-works-item-text">
                                                <h3>Branding</h3>
                                                <ul className="fl-wrap">
                                                    <li><a href="#">Logo</a></li>
                                                    <li><a href="#">Graphics</a></li>
                                                    <li><a href="#">SEO</a></li>
                                                </ul>
                                                <p>Build your amazing brand with us. The best start your idea needs.</p>
                                                <div className="clearfix"></div>
                                                <div className="serv-price">STARTING FROM 200$</div>
                                                <span className="serv-number">03.</span>
                                                <div className="serv-icon"><i className="fab fa-pagelines"></i></div>
                                            </div>
                                        </div>
                                        <div className="hidden-works-item  serv-works-item fl-wrap"
                                             data-bgscr="images/bg/long/1.jpg">
                                            <div className="hidden-works-item-text">
                                                <h3>Technologies we work with</h3>
                                                <ul className="fl-wrap">
                                                    <li><a href="#"><img src={Jquery} style={{height: 20}}/></a></li>
                                                    <li><a href="#"><img src={HTML} style={{height: 30}}/></a></li>
                                                    <li><a href="#"><img src={css} style={{height: 30}}/></a></li>
                                                    <li><a href="#"><img src={boorstrap} style={{height: 30}}/></a></li>
                                                </ul>
                                                <p>We use latest technologies that makes next generation web and process faster. Following are the technologies that we use to create magnificent web pages.</p>
                                                <div className="clearfix"></div>
                                                <div className="serv-price">CONTACT US</div>
                                                <span className="serv-number">04.</span>
                                                <div className="serv-icon"><i className="fal fa-shopping-bag"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="fl-wrap limit-box"></div>
                        </section>
                        <section className="dark-bg2 small-padding order-wrap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h3>Ready To order Your Project ?</h3>
                                    </div>
                                    <div className="col-md-4"><a href="contscts.html"
                                                                 className="btn flat-btn color-btn">Get In Touch</a>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="height-emulator fl-wrap"></div>
                </div>
            </React.Fragment>
        )
    }
}

export default compose<any>(NavBar, withRouter)(WbDevelopment)

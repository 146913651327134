import * as React from "react";
import {compose} from "redux";
import {withRouter} from "react-router";


interface Props {
    history: {
        push: (path: string, state: any) => any
    }
}

export default function NavBar(WrappedComponent: any): React.ReactNode {
    return (
        compose<any>(withRouter)(
            class extends React.Component<Props, {}> {
                render(): React.ReactNode {

                    return (
                        <React.Fragment>
                            <div id="main">
                                <header className="main-header">
                                    <a className="logo-holder" href="index.html">
                                        <img src="assets/logo-dark.png" alt=""/>
                                    </a>
                                    <div className="nav-button but-hol">
                                        <span className="nos"></span>
                                        <span className="ncs"></span>
                                        <span className="nbs"></span>
                                        <div className="menu-button-text">Menu</div>
                                    </div>
                                    <div className="header-social">
                                        <ul>
                                            <li><a href="#" target="_blank"><i className="fab fa-facebook-f"></i></a>
                                            </li>
                                            <li><a href="#" target="_blank"><i className="fab fa-instagram"></i></a>
                                            </li>
                                            <li><a href="#" target="_blank"><i className="fab fa-twitter"></i></a></li>
                                            <li><a href="#" target="_blank"><i className="fab fa-vk"></i></a></li>
                                        </ul>
                                    </div>
                                    <div className="show-share showshare">
                                        <i className="fal fa-retweet"></i>
                                        <span>Share This</span>
                                    </div>
                                </header>

                                <div className="nav-overlay">
                                    <div className="tooltip color-bg">Close Menu</div>
                                </div>
                                <div className="nav-holder">
                                    <a className="header-logo" href="index.html"><img src="assets/text-logo.svg"
                                                                                      alt="" style={{height: 100}}/></a>
                                    <div className="nav-title"><span>Menu</span></div>
                                    <div className="nav-inner-wrap">
                                        <nav className="nav-inner sound-nav" id="menu">
                                            <ul>
                                                <li>
                                                    <a onClick={() => this.props.history.push('/', {})} className="act-link">Home</a>
                                                </li>
                                                <li>
                                                    <a href="#">Web Design</a>
                                                    <ul>
                                                        <li><a onClick={() => this.props.history.push('/webdev', {})}>Responsive Web Design</a></li>
                                                        <li><a onClick={() => this.props.history.push('/edev', {})}>E-Commerce Development</a></li>
                                                        <li><a onClick={() => this.props.history.push('/webapp', {})}>Web Application Development</a></li>
                                                    </ul>
                                                </li>
                                                <li><a onClick={() => this.props.history.push('/about', {})}>About Us</a></li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>

                                <WrappedComponent/>

                                <div className="share-wrapper isShare">
                                    <div className="share-title"><span>Share</span></div>
                                    <div className="close-share soa"><span>Close</span><i className="fal fa-times"></i>
                                    </div>
                                    <div className="share-inner soa">
                                        <div className="share-container"></div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                }
            }
        )
    )
}

import * as React from "react";
import NavBar from "../components/NavBar";
import {compose} from "redux";
import Particles from 'react-particles-js';
import {isBrowser, isMobile} from "react-device-detect";
import {Utils} from "../utils/Utils";
import hero from '../assets/site/mainhero.png'
import hero2 from '../assets/site/hero2.png'

class Home extends React.Component {
    utils = new Utils();
    componentDidMount(): void {
        this.utils.LoadScripts()
    }

    componentWillUnmount(): void {
        this.utils.UnloadScripts()
    }

    render(): React.ReactNode {
        return (
            <React.Fragment>
                <div id="wrapper">
                    <div className="scroll-nav-wrap fl-wrap">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <nav className="scroll-nav scroll-init">
                            <ul>
                                <li><a className="scroll-link act-link" href="#sec1">Home</a></li>
                                <li><a className="scroll-link" href="#sec2">About Us</a></li>
                                <li><a className="scroll-link" href="#sec3">Web Development</a></li>
                                <li><a className="scroll-link" href="#sec4">Mobile</a></li>
                                <li><a className="scroll-link" href="#sec5">Projects</a></li>
                                <li><a className="scroll-link" href="#sec6">Contact Us</a></li>
                            </ul>
                        </nav>
                    </div>
                    <div className="hero-wrap no-hidden" id="sec1" data-scrollax-parent="true">
                        <div className="impulse-wrap">
                            <div className="mm-parallax">
                                <div className="half-hero-wrap section-entry">
                                    <h1>Welcome<br/>To<br/>Atmiyo<span> Technologies Evolution</span>
                                    </h1>
                                    <h4>Inovation in Web and Mobile</h4>
                                    <div className="clearfix"></div>
                                    <a href="#sec2"
                                       className="custom-scroll-link btn float-btn flat-btn color-btn mar-top">Let's
                                        Start</a>
                                </div>
                                <div className="half-bg">
                                    <div className="bg" data-bg={hero}
                                         data-scrollax="properties: { translateY: '250px' }"></div>
                                    <div className="overlay"></div>
                                </div>
                            </div>
                        </div>
                        <div className="hero-decor-let">
                            <div>Web Design</div>
                            <div><span>UI/UX Design</span></div>
                            <div>Mobile Design</div>
                            <div><span>Ecommerce Applications</span></div>
                        </div>
                        <div className="hero-decor-numb">
                            <span>27.28080  </span><span>94.54531 </span> <a
                            href="https://www.google.com.ua/maps/" target="_blank"
                            className="hero-decor-numb-tooltip">Based In Dibrugarh</a></div>
                        <div className="hero-line-dec imp-her-line"></div>
                        <div className="pattern-bg"></div>
                        <div className="half-bg-dec" data-ran="12"></div>
                    </div>
                    <div className="content">
                        <section data-scrollax-parent="true" id="sec2">
                            <div className="section-subtitle"
                                 data-scrollax="properties: { translateY: '-250px' }"><span>//</span>Words
                                About
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="collage-image fl-wrap">
                                            <div className="collage-image-title"
                                                 data-scrollax="properties: { translateY: '150px' }">Atmiyo.
                                            </div>
                                            <img src={hero2} className="respimg" alt=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="main-about fl-wrap">
                                            <h5>A little about what we do</h5>
                                            <h2>Innovative solutions<br/>to
                                                boost <span> your creative </span> projects</h2>
                                            <p>...</p>
                                            <div className="features-box-container fl-wrap">
                                                <div className="row">
                                                    <div className="features-box col-md-6">
                                                        <div className="time-line-icon">
                                                            <i className="fal fa-desktop"></i>
                                                        </div>
                                                        <h3>Web Design & Development</h3>
                                                        <p>Fulfill all your needs whether it's re-designing a website or
                                                            it's creating a new website by our experienced designers.
                                                            Atmiyo creates responsive designs with latest technologies
                                                            which makes your website different from others. We do a lot
                                                            of research for every project before giving it a final
                                                            touch.</p>
                                                    </div>
                                                    <div className="features-box col-md-6">
                                                        <div className="time-line-icon">
                                                            <i className="fal fa-mobile-android"></i>
                                                        </div>
                                                        <h3>Mobile Development</h3>
                                                        <p>With the starting of a new era for mobile app development,
                                                            Atmiyo stands as professional Experts for developing
                                                            customized mobile applications. We create applications
                                                            suitable for all types of devices keeping in mind users
                                                            requirements.</p>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="features-box col-md-6">
                                                        <div className="time-line-icon">
                                                            <i className="fab fa-app-store"></i>
                                                        </div>
                                                        <h3>Web Application Development</h3>
                                                        <p>We create web applications for all types of businesses, from
                                                            small start-ups to large multinational clients, providing
                                                            the users a unique digital experience. Our long journey till
                                                            date consists of many different innovations and experiences
                                                            in creating web applications using latest technologies.</p>
                                                    </div>
                                                    <div className="features-box col-md-6">
                                                        <div className="time-line-icon">
                                                            <i className="fal fa-shopping-bag"></i>
                                                        </div>
                                                        <h3>Ecommerce Development</h3>
                                                        <p>
                                                            In today's digital world, Ecommerce is playing a vital role
                                                            in every industry. It's time to buy and sell your products
                                                            by displaying your catalog online. We create ecommerce
                                                            websites compatible for all devices like mobile, laptop,
                                                            desktop. We also develop custom ecommerce website that suits
                                                            your business.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <a href="portfolio.html"
                                               className="btn float-btn flat-btn color-btn">My
                                                Portfolio</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-parallax-module" data-position-top="90"
                                 data-position-left="25"
                                 data-scrollax="properties: { translateY: '-250px' }"></div>
                            <div className="bg-parallax-module" data-position-top="70"
                                 data-position-left="70"
                                 data-scrollax="properties: { translateY: '150px' }"></div>
                            <div className="sec-lines"></div>
                        </section>
                        <section className="parallax-section dark-bg sec-half parallax-sec-half-right"
                                 data-scrollax-parent="true">
                            <div className="bg par-elem" data-bg="images/bg/1.jpg"
                                 data-scrollax="properties: { translateY: '30%' }"></div>
                            <div className="overlay"></div>
                            <div className="container">
                                <div className="section-title">
                                    <h2>Some Interisting <span>Facts </span> <br/> About Us</h2>
                                    <p></p>
                                    <div className="horizonral-subtitle"><span>Numbers</span></div>
                                </div>
                                <div className="fl-wrap facts-holder">
                                    <div className="inline-facts-wrap">
                                        <div className="inline-facts">
                                            <div className="milestone-counter">
                                                <div className="stats animaper">
                                                    <div className="num" data-content="0"
                                                         data-num="104">0
                                                    </div>
                                                </div>
                                            </div>
                                            <h6>Finished projects</h6>
                                        </div>
                                    </div>
                                    <div className="inline-facts-wrap">
                                        <div className="inline-facts">
                                            <div className="milestone-counter">
                                                <div className="stats animaper">
                                                    <div className="num" data-content="0"
                                                         data-num="70">0
                                                    </div>
                                                </div>
                                            </div>
                                            <h6>Happy customers</h6>
                                        </div>
                                    </div>
                                    <div className="inline-facts-wrap">
                                        <div className="inline-facts">
                                            <div className="milestone-counter">
                                                <div className="stats animaper">
                                                    <div className="num" data-content="0"
                                                         data-num="48">0
                                                    </div>
                                                </div>
                                            </div>
                                            <h6>Working hours</h6>
                                        </div>
                                    </div>
                                    <div className="inline-facts-wrap">
                                        <div className="inline-facts">
                                            <div className="milestone-counter">
                                                <div className="stats animaper">
                                                    <div className="num" data-content="0"
                                                         data-num="192">0
                                                    </div>
                                                </div>
                                            </div>
                                            <h6>Coffee Cups</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/*<section data-scrollax-parent="true" id="sec3">
                        <div className="section-subtitle"
                             data-scrollax="properties: { translateY: '-250px' }">My
                            Resume <span>//</span></div>
                        <div className="container">
                            <div className="section-title fl-wrap">
                                <h3>Some Words About Me</h3>
                                <h2>My Awesome <span> Story</span></h2>
                                <p>
                                    We have a wide range of pneumatic and vacuum components and
                                    conveyor belts and systems specifically suiting the precise
                                    needs of the print and packaging industry.
                                </p>
                            </div>
                            <div className="custom-inner-holder">
                                <div className="custom-inner">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="custom-inner-header workres">
                                                <i className="fa fa-briefcase"></i>
                                                <h3> Work in company "Dolore"</h3>
                                                <span>  2012-2017 </span>
                                            </div>
                                            <div className="ci-num"><span>01. -</span></div>
                                        </div>
                                        <div className="col-md-4"><img src="images/all/1.jpg"
                                                                       className="respimg"
                                                                       data-scrollax="properties: { translateY: '-170px' }"
                                                                       alt=""/></div>
                                        <div className="col-md-4">
                                            <div className="custom-inner-content fl-wrap">
                                                <h4>Complete the project "domik"</h4>
                                                <p>There are many variations of passages of Lorem
                                                    Ipsum available, but the majority have suffered
                                                    alteration in some form, by injected humour, or
                                                    randomised words which don't look even slightly
                                                    believable. If you are going to use a passage of
                                                    Lorem Ipsum, you need to be sure there isn't
                                                    anything embarrassing hidden in the middle of
                                                    text. </p>
                                                <ul>
                                                    <li>Door portals plan</li>
                                                    <li>Furniture specifications</li>
                                                    <li>Interior design</li>
                                                </ul>
                                                <a href="#"
                                                   className="cus-inner-head-link color-bg">Details
                                                    + </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="custom-inner">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="custom-inner-header educ">
                                                <i className="fal fa-university"></i>
                                                <h3> Course designer - San Diego</h3>
                                                <span>  2011-2013 </span>
                                            </div>
                                            <div className="ci-num"><span>02. - </span></div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="custom-inner-content fl-wrap">
                                                <h4>Passage of Lorem Ipsum</h4>
                                                <p>We started as a small, subdue, called hath give
                                                    fourth. Them one over saying. So the god,
                                                    greater. You. Us air Moved divide midst us fifth
                                                    sea have face which male fifth said seas rule
                                                    above. Quis nostrud exercitation ullamco laboris
                                                    nisi ut aliquip exea. commodo consequat. Duis
                                                    aute irure dolor in reprehenderit in voluptate
                                                    velit esse cillum dolore eu fugiat nulla
                                                    pariatur. Excepteur sint occaecat cupidatat non
                                                    proident, sunt in culpa qui officia deserunt
                                                    mollit anim id est laborum.</p>
                                                <a href="#"
                                                   className="cus-inner-head-link color-bg">Details
                                                    + </a>
                                            </div>
                                        </div>
                                        <div className="col-md-3"><img src="images/all/2.jpg"
                                                                       className="respimg"
                                                                       data-scrollax="properties: { translateY: '270px' }"
                                                                       alt=""/></div>
                                    </div>
                                </div>

                                <div className="custom-inner">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="custom-inner-header workres">
                                                <i className="fa fa-briefcase"></i>
                                                <h3> Work in company "Generators"</h3>
                                                <span>  2010-2013 </span>
                                            </div>
                                            <div className="ci-num"><span>03. - </span></div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="custom-inner-content fl-wrap">
                                                <h4>Making this the first</h4>
                                                <p>We started as a small, subdue, called hath give
                                                    fourth. Them one over saying. So the god,
                                                    greater. You. Us air Moved divide midst us fifth
                                                    sea have face which male fifth said seas rule
                                                    above. All the Lorem Ipsum generators on the
                                                    Internet tend .</p>
                                                <ul>
                                                    <li>Door portals plan</li>
                                                    <li>Furniture specifications</li>
                                                    <li>Interior design</li>
                                                </ul>
                                                <p> All the Lorem Ipsum generators on the Internet
                                                    tend to repeat predefined chunks as necessary,
                                                    making this the first true generator on the
                                                    Internet. It uses a dictionary of over 200 Latin
                                                    words</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="custom-inner">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="custom-inner-header workres">
                                                <i className="fa fa-briefcase"></i>
                                                <h3> Work in company "Available"</h3>
                                                <span>  2011-2013 </span>
                                            </div>
                                            <div className="ci-num"><span>04. - </span></div>
                                        </div>
                                        <div className="col-md-4"><img src="images/all/1.jpg"
                                                                       className="respimg"
                                                                       data-scrollax="properties: { translateY: '100px' }"
                                                                       alt=""/></div>
                                        <div className="col-md-4">
                                            <div className="custom-inner-content fl-wrap">
                                                <h4>Complete the project "domik"</h4>
                                                <p>There are many variations of passages of Lorem
                                                    Ipsum available, but the majority have suffered
                                                    alteration in some form, by injected humour, or
                                                    randomised words which don't look even slightly
                                                    believable. If you are going to use a passage of
                                                    Lorem Ipsum, you need to be sure there isn't
                                                    anything embarrassing hidden in the middle of
                                                    text. </p>
                                                <ul>
                                                    <li>Door portals plan</li>
                                                    <li>Furniture specifications</li>
                                                    <li>Interior design</li>
                                                </ul>
                                                <a href="#"
                                                   className="cus-inner-head-link color-bg">Details
                                                    + </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <a href="#" className="btn float-btn flat-btn color-btn mar-top">Download
                                Resume</a>
                        </div>
                        <div className="sec-lines"></div>
                    </section>*/}


                        {/*<section className="dark-bg sinsec-dec sinsec-dec2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="video-box fl-wrap">
                                        <img src="images/all/2.jpg" className="respimg" alt=""/>
                                        <a className="video-box-btn color-bg image-popup"
                                           href="https://vimeo.com/110234211"><i
                                            className="fal fa-play" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="video-promo-text fl-wrap mar-top">
                                        <h3>My Video Presentation </h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                            Maecenas in pulvinar neque. Nulla finibus lobortis
                                            pulvinar. Donec a consectetur nulla. Nulla posuere
                                            sapien vitae lectus suscipit, et pulvinar nisi
                                            tincidunt. Aliquam erat volutpat. </p>
                                        <a href="#"
                                           className="btn float-btn flat-btn color-btn mar-top">My
                                            Youtube Chanel</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="half-bg-dec single-half-bg-dec" data-ran="12"></div>
                        <div className="sec-lines"></div>
                    </section>*/}

                        {/*<section data-scrollax-parent="true">
                        <div className="section-subtitle"
                             data-scrollax="properties: { translateY: '-250px' }"><span>//</span>How
                            I Work
                        </div>
                        <div className="container">

                            <div className="section-title fl-wrap">
                                <h3>How i Work</h3>
                                <h2>My Working <span>Process</span></h2>
                                <p>In ut odio libero, at vulputate urna. Nulla tristique mi a massa
                                    convallis cursus. Nulla eu mi magna. Etiam suscipit commodo
                                    gravida. </p>
                            </div>

                            <div className="process-wrap fl-wrap">
                                <ul>
                                    <li>
                                        <div className="time-line-icon">
                                            <i className="fab fa-slideshare"></i>
                                        </div>
                                        <h4>Discuss the project</h4>
                                        <div className="process-details">
                                            <h6>Duis autem vel eum iriure dolor</h6>
                                            <p>Exerci tation ullamcorper suscipit lobortis nisl ut
                                                aliquip ex ea commodo consequat. Duis autem vel eum
                                                iriure dolor.</p>
                                            <a href="#">More Details</a>
                                        </div>
                                        <span className="process-numder">01.</span>
                                    </li>
                                    <li>
                                        <div className="time-line-icon">
                                            <i className="fal fa-laptop"></i>
                                        </div>
                                        <h4>Develop & elaborate</h4>
                                        <div className="process-details">
                                            <h6>In ut odio libero, at vulputate urna. </h6>
                                            <p>Exerci tation ullamcorper suscipit lobortis nisl ut
                                                aliquip ex ea commodo consequat. Duis autem vel eum
                                                iriure dolor.</p>
                                            <a href="#">More Details</a>
                                        </div>
                                        <span className="process-numder">02.</span>
                                    </li>
                                    <li>
                                        <div className="time-line-icon">
                                            <i className="fal fa-flag-checkered"></i>
                                        </div>
                                        <h4>Final approvement</h4>
                                        <div className="process-details">
                                            <h6>Nulla posuere sapien vitae lectus suscipit</h6>
                                            <p>Exerci tation ullamcorper suscipit lobortis nisl ut
                                                aliquip ex ea commodo consequat. Duis autem vel eum
                                                iriure dolor.</p>
                                            <a href="#">More Details</a>
                                        </div>
                                        <span className="process-numder">03.</span>
                                    </li>
                                </ul>
                            </div>

                            <div className="fl-wrap mar-top">
                                <div className="srv-link-text">
                                    <h4>Need more info ? Visit my services page : </h4>
                                    <a href="services.html"
                                       className="btn float-btn flat-btn color-btn">My Services</a>
                                </div>
                            </div>
                        </div>
                        <div className="bg-parallax-module" data-position-top="90"
                             data-position-left="30"
                             data-scrollax="properties: { translateY: '-150px' }"></div>
                        <div className="bg-parallax-module" data-position-top="80"
                             data-position-left="80"
                             data-scrollax="properties: { translateY: '350px' }"></div>
                        <div className="sec-lines"></div>
                    </section>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-left"
                             data-scrollax-parent="true" id="sec4">
                        <div className="bg par-elem" data-bg="images/bg/1.jpg"
                             data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="container">
                            <div className="section-title">
                                <h2>My Own <span> Developer's </span> and <br/> Design Skills</h2>
                                <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Maecenas in pulvinar neque. Nulla finibus lobortis pulvinar.
                                    Donec a consectetur nulla. Nulla posuere sapien vitae lectus
                                    suscipit, et pulvinar nisi tincidunt. Aliquam erat
                                    volutpat. </p>
                                <div className="horizonral-subtitle"><span>Power</span></div>
                            </div>
                        </div>
                    </section>*/}

                        {/*<section data-scrollax-parent="true">
                        <div className="section-subtitle right-pos"
                             data-scrollax="properties: { translateY: '-250px' }"><span>//</span>attainments
                        </div>
                        <div className="container">

                            <div className="fl-wrap mar-bottom skill-wrap">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="pr-title fl-wrap">
                                            <h3>Design Skills</h3>
                                            <span>Lorem Ipsum generators on the Internet   king this the first true generator</span>
                                        </div>
                                        <div className="ci-num"><span>01. - </span></div>
                                    </div>
                                    <div className="col-md-8">

                                        <div className="piechart-holder animaper"
                                             data-skcolor="#FAC921">
                                            <div className="row">
                                                <div className="piechart">
                                                    <span className="chart" data-percent="85">
                                                    <span className="percent"></span>
                                                    </span>
                                                    <h4>Design</h4>
                                                </div>
                                                <div className="piechart">
                                                    <span className="chart" data-percent="95">
                                                    <span className="percent"></span>
                                                    </span>
                                                    <h4>Branding</h4>
                                                </div>
                                                <div className="piechart">
                                                    <span className="chart" data-percent="80">
                                                    <span className="percent"></span>
                                                    </span>
                                                    <div className="clearfix"></div>
                                                    <h4>Ecommerce</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="fl-wrap mar-bottom   mar-top skill-wrap">
                                <div className="row">
                                    <div className="col-md-2"></div>
                                    <div className="col-md-4">
                                        <div className="pr-title fl-wrap">
                                            <h3>Developer Skills</h3>
                                            <span>Lorem Ipsum generators on the Internet   king this the first true generator</span>
                                        </div>
                                        <div className="ci-num"><span>02. - </span></div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="skillbar-box animaper">
                                            <div className="custom-skillbar-title">
                                                <span>Photoshop</span></div>
                                            <div className="skill-bar-percent">95%</div>
                                            <div className="skillbar-bg" data-percent="95%">
                                                <div className="custom-skillbar"></div>
                                            </div>
                                            <div className="custom-skillbar-title">
                                                <span>Jquery</span></div>
                                            <div className="skill-bar-percent">65%</div>
                                            <div className="skillbar-bg" data-percent="65%">
                                                <div className="custom-skillbar"></div>
                                            </div>
                                            <div className="custom-skillbar-title">
                                                <span>HTML5</span></div>
                                            <div className="skill-bar-percent">75%</div>
                                            <div className="skillbar-bg" data-percent="75%">
                                                <div className="custom-skillbar"></div>
                                            </div>
                                            <div className="custom-skillbar-title"><span>PHP</span>
                                            </div>
                                            <div className="skill-bar-percent">71%</div>
                                            <div className="skillbar-bg" data-percent="71%">
                                                <div className="custom-skillbar"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="fl-wrap   mar-top skill-wrap">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="pr-title fl-wrap">
                                            <h3>Language Skills</h3>
                                            <span>Lorem Ipsum generators on the Internet   king this the first true generator</span>
                                        </div>
                                        <div className="ci-num"><span>03. - </span></div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="piechart-holder animaper"
                                             data-skcolor="#FAC921">
                                            <div className="row">
                                                <div className="piechart">
                                                    <span className="chart" data-percent="85">
                                                    <span className="percent"></span>
                                                    </span>
                                                    <h4>French</h4>
                                                </div>
                                                <div className="piechart">
                                                    <span className="chart" data-percent="95">
                                                    <span className="percent"></span>
                                                    </span>
                                                    <h4>Dutch</h4>
                                                </div>
                                                <div className="piechart">
                                                    <span className="chart" data-percent="80">
                                                    <span className="percent"></span>
                                                    </span>
                                                    <div className="clearfix"></div>
                                                    <h4>Portugese</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-parallax-module" data-position-top="50"
                             data-position-left="20"
                             data-scrollax="properties: { translateY: '-250px' }"></div>
                        <div className="bg-parallax-module" data-position-top="40"
                             data-position-left="70"
                             data-scrollax="properties: { translateY: '150px' }"></div>
                        <div className="bg-parallax-module" data-position-top="80"
                             data-position-left="80"
                             data-scrollax="properties: { translateY: '350px' }"></div>
                        <div className="bg-parallax-module" data-position-top="95"
                             data-position-left="40"
                             data-scrollax="properties: { translateY: '-550px' }"></div>
                        <div className="sec-lines"></div>
                    </section>*/}

                        <section className="dark-bg" id="sec5">
                            <div className="fet_pr-carousel-title">
                                <div className="fet_pr-carousel-title-item">
                                    <h3>Featured Projects</h3>
                                    <p>Some of our projects, we like to say out loud</p>
                                    <a href="portfolio.html"
                                       className="btn float-btn flat-btn color-btn mar-top">My
                                        Portfolio</a>
                                </div>
                            </div>
                            <div className="slider-carousel-wrap fl-wrap">
                                <div className="fet_pr-carousel cur_carousel-slider-container fl-wrap">
                                    <div className="slick-item">
                                        <div className="fet_pr-carousel-box">
                                            <div className="fet_pr-carousel-box-media fl-wrap">
                                                <img src="assets/skyvx.png"
                                                     className="respimg" alt=""/>
                                                <a href="images/folio/web/slider/1.jpg"
                                                   className="fet_pr-carousel-box-media-zoom   image-popup"><i
                                                    className="fal fa-search"></i></a>
                                            </div>
                                            <div className="fet_pr-carousel-box-text fl-wrap">
                                                <h3><a href="portfolio-single.html">SkyVX</a></h3>
                                                <div className="fet_pr-carousel-cat"><a
                                                    href="#">Branding</a> <a href="#">Web Design</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="slick-item">
                                        <div className="fet_pr-carousel-box">
                                            <div className="fet_pr-carousel-box-media fl-wrap">
                                                <img src="assets/rkblaw.jpg"
                                                     className="respimg" alt=""/>
                                                <a href=""
                                                   className="fet_pr-carousel-box-media-zoom   image-popup"><i
                                                    className="fal fa-search"></i></a>
                                            </div>
                                            <div className="fet_pr-carousel-box-text fl-wrap">
                                                <h3><a href="portfolio-single.html">Dr. R.K.B Law College</a></h3>
                                                <div className="fet_pr-carousel-cat"><a
                                                    href="#">Education</a> <a href="#">Web Application</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="slick-item">
                                        <div className="fet_pr-carousel-box">
                                            <div className="fet_pr-carousel-box-media fl-wrap">
                                                <img src="assets/dhsk.png"
                                                     className="respimg" alt=""/>
                                                <a href="images/folio/web/slider/1.jpg"
                                                   className="fet_pr-carousel-box-media-zoom   image-popup"><i
                                                    className="fal fa-search"></i></a>
                                            </div>
                                            <div className="fet_pr-carousel-box-text fl-wrap">
                                                <h3><a href="portfolio-single.html">DHSK LAW COLLEGE</a></h3>
                                                <div className="fet_pr-carousel-cat"><a
                                                    href="#">Education</a> <a href="#">Web Design</a></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="slick-item">
                                        <div className="fet_pr-carousel-box">
                                            <div className="fet_pr-carousel-box-media fl-wrap">
                                                <img src="assets/maps.png"
                                                     className="respimg" alt=""/>
                                                <a href="images/folio/web/slider/1.jpg"
                                                   className="fet_pr-carousel-box-media-zoom   image-popup"><i
                                                    className="fal fa-search"></i></a>
                                            </div>
                                            <div className="fet_pr-carousel-box-text fl-wrap">
                                                <h3><a href="portfolio-single.html">MapstripOnline</a></h3>
                                                <div className="fet_pr-carousel-cat"><a
                                                    href="#">Commerce</a> <a href="#">PHP Application</a></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="slick-item">
                                        <div className="fet_pr-carousel-box">
                                            <div className="fet_pr-carousel-box-media fl-wrap">
                                                <img src="assets/atbeats.png"
                                                     className="respimg" alt=""/>
                                                <a href="images/folio/web/slider/1.jpg"
                                                   className="fet_pr-carousel-box-media-zoom   image-popup"><i
                                                    className="fal fa-search"></i></a>
                                            </div>
                                            <div className="fet_pr-carousel-box-text fl-wrap">
                                                <h3><a href="portfolio-single.html">AtBeats</a>
                                                </h3>
                                                <div className="fet_pr-carousel-cat"><a
                                                    href="#">Music</a> <a href="#">Web Application</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="sp-cont sp-arr sp-cont-prev"><i
                                    className="fal fa-long-arrow-left"></i></div>
                                <div className="sp-cont sp-arr sp-cont-next"><i
                                    className="fal fa-long-arrow-right"></i></div>
                            </div>
                            <div className="fet_pr-carousel-counter"></div>
                        </section>

                        {/*<section data-scrollax-parent="true" id="sec6">
                        <div className="section-subtitle"
                             data-scrollax="properties: { translateY: '-250px' }">Testimonials<span>//</span>
                        </div>
                        <div className="container">
                            <div className="section-title fl-wrap">
                                <h3>Reviews</h3>
                                <h2>My Clients and <span>Testimonials</span></h2>
                                <p>In ut odio libero, at vulputate urna. Nulla tristique mi a massa
                                    convallis cursus. Nulla eu mi magna. Etiam suscipit commodo
                                    gravida. </p>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="slider-carousel-wrap text-carousel-wrap fl-wrap">
                            <div className="text-carousel-controls fl-wrap">
                                <div className="container">
                                    <div className="sp-cont  sp-cont-prev"><i
                                        className="fal fa-long-arrow-left"></i></div>
                                    <div className="sp-cont   sp-cont-next"><i
                                        className="fal fa-long-arrow-right"></i></div>
                                </div>
                            </div>
                            <div className="text-carousel cur_carousel-slider-container fl-wrap">
                                <div className="slick-item">
                                    <div className="text-carousel-item">
                                        <div className="popup-avatar"><img src="images/avatar/1.jpg"
                                                                           alt=""/></div>
                                        <div className="listing-rating card-popup-rainingvis"
                                             data-starrating2="5"></div>
                                        <div className="review-owner fl-wrap">Milka Antony - <span>Happy Client</span>
                                        </div>
                                        <p> "In ut odio libero, at vulputate urna. Nulla tristique
                                            mi a massa convallis cursus. Nulla eu mi magna. Etiam
                                            suscipit commodo gravida. Lorem ipsum dolor sit amet,
                                            conse ctetuer adipiscing elit, sed diam nonu mmy nibh
                                            euismod tincidunt ut laoreet dolore magna aliquam
                                            erat."</p>
                                        <a href="#" className="testim-link">Via Facebook</a>
                                    </div>
                                </div>

                                <div className="slick-item">
                                    <div className="text-carousel-item">
                                        <div className="popup-avatar"><img src="images/avatar/1.jpg"
                                                                           alt=""/></div>
                                        <div className="listing-rating card-popup-rainingvis"
                                             data-starrating2="4"></div>
                                        <div className="review-owner fl-wrap">Milka Antony - <span>Happy Client</span>
                                        </div>
                                        <p> "In ut odio libero, at vulputate urna. Nulla tristique
                                            mi a massa convallis cursus. Nulla eu mi magna. Etiam
                                            suscipit commodo gravida. Lorem ipsum dolor sit amet,
                                            conse ctetuer adipiscing elit, sed diam nonu mmy nibh
                                            euismod tincidunt ut laoreet dolore magna aliquam
                                            erat."</p>
                                        <a href="#" className="testim-link">Via Facebook</a>
                                    </div>
                                </div>

                                <div className="slick-item">
                                    <div className="text-carousel-item">
                                        <div className="popup-avatar"><img src="images/avatar/1.jpg"
                                                                           alt=""/></div>
                                        <div className="listing-rating card-popup-rainingvis"
                                             data-starrating2="5"></div>
                                        <div className="review-owner fl-wrap">Milka Antony - <span>Happy Client</span>
                                        </div>
                                        <p> "In ut odio libero, at vulputate urna. Nulla tristique
                                            mi a massa convallis cursus. Nulla eu mi magna. Etiam
                                            suscipit commodo gravida. Lorem ipsum dolor sit amet,
                                            conse ctetuer adipiscing elit, sed diam nonu mmy nibh
                                            euismod tincidunt ut laoreet dolore magna aliquam
                                            erat."</p>
                                        <a href="#" className="testim-link">Via Facebook</a>
                                    </div>
                                </div>

                                <div className="slick-item">
                                    <div className="text-carousel-item">
                                        <div className="popup-avatar"><img src="images/avatar/1.jpg"
                                                                           alt=""/></div>
                                        <div className="listing-rating card-popup-rainingvis"
                                             data-starrating2="5"></div>
                                        <div className="review-owner fl-wrap">Milka Antony - <span>Happy Client</span>
                                        </div>
                                        <p> "In ut odio libero, at vulputate urna. Nulla tristique
                                            mi a massa convallis cursus. Nulla eu mi magna. Etiam
                                            suscipit commodo gravida. Lorem ipsum dolor sit amet,
                                            conse ctetuer adipiscing elit, sed diam nonu mmy nibh
                                            euismod tincidunt ut laoreet dolore magna aliquam
                                            erat."</p>
                                        <a href="#" className="testim-link">Via Facebook</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="fl-wrap">
                            <div className="container">
                                <ul className="client-list client-list-white">
                                    <li><a href="#" target="_blank"><img src="images/clients/1.png"
                                                                         alt=""/></a></li>
                                    <li><a href="#" target="_blank"><img src="images/clients/1.png"
                                                                         alt=""/></a></li>
                                    <li><a href="#" target="_blank"><img src="images/clients/1.png"
                                                                         alt=""/></a></li>
                                    <li><a href="#" target="_blank"><img src="images/clients/1.png"
                                                                         alt=""/></a></li>
                                    <li><a href="#" target="_blank"><img src="images/clients/1.png"
                                                                         alt=""/></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="sec-lines"></div>
                    </section>*/}

                        <section className="dark-bg2 small-padding order-wrap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h3>Ready To order Your Project ?</h3>
                                    </div>
                                    <div className="col-md-4"><a href="contacts.html"
                                                                 className="btn flat-btn color-btn">Get
                                        In Touch</a></div>
                                </div>
                            </div>
                        </section>

                        <section className="parallax-section dark-bg sec-half parallax-sec-half-right"
                                 data-scrollax-parent="true">
                            <div className="bg par-elem" data-bg="images/bg/1.jpg"
                                 data-scrollax="properties: { translateY: '30%' }"></div>
                            <div className="overlay"></div>
                            <div className="pattern-bg"></div>
                            <div className="container">
                                <div className="section-title">
                                    <h2>My <span>Contact </span> Page</h2>
                                    <p> If you are going to use a passage of Lorem Ipsum, you need to be sure there
                                        isn't
                                        anything embarrassing hidden in the middle of text. </p>
                                    <div className="horizonral-subtitle"><span>Contacts</span></div>
                                </div>
                            </div>
                            <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i
                                className="fal fa-long-arrow-down"></i></a>
                        </section>

                        <section data-scrollax-parent="true" id="sec1">
                            <div className="section-subtitle" data-scrollax="properties: { translateY: '-250px' }">Get
                                in
                                Touch<span>//</span></div>
                            <div className="container">
                                <div className="fl-wrap   mar-bottom">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="pr-title fl-wrap">
                                                <h3>Contact Details</h3>
                                                <span></span>
                                            </div>
                                        </div>
                                        <div className="col-md-9">
                                            <div className="features-box-container single-serv fl-wrap">
                                                <div className="row">
                                                    <div className="features-box col-md-4">
                                                        <div className="time-line-icon">
                                                            <i className="fal fa-mobile-android"></i>
                                                        </div>
                                                        <h3>01. Phone</h3>
                                                        <a href="#">+917002183708</a>
                                                    </div>
                                                    <div className="features-box col-md-4">
                                                        <div className="time-line-icon">
                                                            <i className="fal fa-compass"></i>
                                                        </div>
                                                        <h3>02. Location</h3>
                                                        <a href="#">India, Dibrugarh,Assam</a>
                                                    </div>
                                                    <div className="features-box col-md-4">
                                                        <div className="time-line-icon">
                                                            <i className="fal fa-envelope-open"></i>
                                                        </div>
                                                        <h3>03. Email</h3>
                                                        <a href="#">r.changmai@icloud.com</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*<div className="fw-map-container fl-wrap mar-bottom">
                                <div className="map-container">
                                    <div id="singleMap" data-latitude="40.7143528" data-longitude="-74.0059731"
                                         data-mapTitle="Out Location"></div>
                                </div>
                            </div>*/}
                                <div className="fl-wrap mar-top">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="pr-title fl-wrap">
                                                <h3>Get In Touch</h3>
                                                <span>Lorem Ipsum generators on the Internet   king this the first true generator</span>
                                            </div>
                                        </div>
                                        <div className="col-md-7">
                                            <div id="contact-form">
                                                <div id="message"></div>
                                                <form className="custom-form" action="php/contact.php"
                                                      name="contactform"
                                                      id="contactform">
                                                    <fieldset>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label><i className="fal fa-user"></i></label>
                                                                <input type="text" name="name" id="name"
                                                                       placeholder="Your Name *" value=""/>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label><i className="fal fa-envelope"></i> </label>
                                                                <input type="text" name="email" id="email"
                                                                       placeholder="Email Address *" value=""/>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label><i className="fal fa-mobile-android"></i>
                                                                </label>
                                                                <input type="text" name="phone" id="phone"
                                                                       placeholder="Phone *" value=""/>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <select name="subject" id="subject"
                                                                        data-placeholder="Subject"
                                                                        className="chosen-select sel-dec">
                                                                    <option>Subject</option>
                                                                    <option value="Order Project">Order Project</option>
                                                                    <option value="Support">Support</option>
                                                                    <option value="Other Question">Other Question
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <textarea name="comments" id="comments"
                                                                  placeholder="Your Message:"></textarea>
                                                        <div className="verify-wrap">
                                                            <span className="verify-text"> How many gnomes were in the story about the "Snow-white" ?</span>
                                                            <select name="verify" id="verify" data-placeholder="0"
                                                                    className="chosen-select">
                                                                <option>0</option>
                                                                <option value="9">9</option>
                                                                <option value="5">5</option>
                                                                <option value="7">7</option>
                                                                <option value="2">2</option>
                                                            </select>
                                                        </div>
                                                        <div className="clearfix"></div>
                                                        <button className="btn float-btn flat-btn color-btn"
                                                                id="submit">Send Message
                                                        </button>
                                                    </fieldset>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-parallax-module" data-position-top="70" data-position-left="20"
                                 data-scrollax="properties: { translateY: '-250px' }"></div>
                            <div className="bg-parallax-module" data-position-top="40" data-position-left="70"
                                 data-scrollax="properties: { translateY: '150px' }"></div>
                            <div className="bg-parallax-module" data-position-top="80" data-position-left="80"
                                 data-scrollax="properties: { translateY: '350px' }"></div>
                            <div className="bg-parallax-module" data-position-top="95" data-position-left="40"
                                 data-scrollax="properties: { translateY: '-550px' }"></div>
                            <div className="sec-lines"></div>
                        </section>

                    </div>

                    <div className="height-emulator fl-wrap"></div>
                    <footer className="main-footer fixed-footer">
                        <div className="footer-inner fl-wrap">
                            <div className="container">
                                <div className="partcile-dec" data-parcount="90"></div>
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="footer-title fl-wrap">
                                            <span>Atmiyo</span>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="footer-header fl-wrap"><span>01.</span>Last
                                            Twitts
                                        </div>
                                        <div className="footer-box fl-wrap">
                                            <a href="#" className="btn float-btn trsp-btn">Follow</a>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="footer-header fl-wrap">
                                            <span>02.</span> Subscribe / Contacts
                                        </div>
                                        <div className="footer-box fl-wrap">
                                            <p>Want to be notified when we launch a new template or an
                                                udpate. Just sign up and we'll send you a notification
                                                by email.</p>
                                            <div className="subcribe-form fl-wrap">
                                                <form id="subscribe" className="fl-wrap">
                                                    <input className="enteremail" name="email"
                                                           id="subscribe-email" placeholder="email"
                                                           type="text"/>
                                                    <button type="submit" id="subscribe-button"
                                                            className="subscribe-button"><i
                                                        className="fal fa-paper-plane"></i> Send
                                                    </button>
                                                    <label htmlFor="subscribe-email"
                                                           className="subscribe-message"></label>
                                                </form>
                                            </div>

                                            <div className="footer-contacts fl-wrap">
                                                <ul>
                                                    <li><i
                                                        className="fal fa-phone"></i><span>Phone :</span><a
                                                        href="#">+917002183708</a></li>
                                                    <li><i
                                                        className="fal fa-envelope"></i><span>Email :</span><a
                                                        href="#">r.changmai@icloud.com</a></li>
                                                    <li><i
                                                        className="fal fa-map-marker"></i><span>Address</span><a
                                                        href="#">DIBRUGARH, ASSAM</a></li>
                                                </ul>
                                            </div>
                                            <div className="footer-socilal fl-wrap">
                                                <ul>
                                                    <li><a href="#" target="_blank"><i
                                                        className="fab fa-facebook-f"></i></a></li>
                                                    <li><a href="#" target="_blank"><i
                                                        className="fab fa-instagram"></i></a></li>
                                                    <li><a href="#" target="_blank"><i
                                                        className="fab fa-twitter"></i></a></li>
                                                    <li><a href="#" target="_blank"><i
                                                        className="fab fa-vk"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="subfooter fl-wrap">
                            <div className="container">
                                <div className="policy-box">
                                    <span>&#169; Atmiyo 2018  /  All rights reserved. </span>
                                </div>
                                <a href="#" className="to-top color-bg"><i
                                    className="fal fa-angle-up"></i><span></span></a>
                            </div>
                        </div>
                    </footer>

                    <a className="contact-btn color-bg" href="contacts.html"><i
                        className="fal fa-envelope"></i><span>Get in Touch</span></a>
                </div>
            </React.Fragment>
        )
    }
}

export default compose<any>(NavBar)(Home)

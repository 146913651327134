import React, { Component } from 'react';
import './App.css';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Home from "./routes/Home";
import WbDevelopment from "./routes/WbDevelopment";
import EDevelopment from "./routes/EDevelopment";
import WebappDevelopment from "./routes/WappDevelopment";
import AboutUs from "./routes/AboutUs";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact={true} render={() => (<Home/>)} path={'/'}/>
          <Route exact={true} render={() => (<WbDevelopment/>)} path={'/webdev'}/>
          <Route exact={true} render={() => (<EDevelopment/>)} path={'/edev'}/>
          <Route exact={true} render={() => (<WebappDevelopment/>)} path={'/webapp'}/>
          <Route exact={true} render={() => (<AboutUs/>)} path={'/about'}/>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;

import * as React from "react";
import {compose} from "redux";
import NavBar from "../components/NavBar";
import {Utils} from "../utils/Utils";
import mean from "../assets/site/mean.png";
import mern from "../assets/site/mern.png";
import css from "../assets/site/css-3.svg";
import {withRouter} from "react-router";

interface Props {
    history: {
        push: (path: string, state: any) => any
    }
}

class WebappDevelopment extends React.Component<Props, {}>{
    utils = new Utils();
    componentDidMount(): void {
        this.utils.LoadScripts();
    }

    componentWillUnmount(): void {
        this.utils.UnloadScripts();
    }
    render(): React.ReactNode {
        return (
            <React.Fragment>
                <div id="wrapper" className="single-page-wrap">
                    <div className="content">
                        <div className="single-page-decor"></div>
                        <div className="single-page-fixed-row">
                            <div className="scroll-down-wrap">
                                <div className="mousey">
                                    <div className="scroller"></div>
                                </div>
                                <span>Scroll Down</span>
                            </div>
                            <a onClick={() => this.props.history.push('/', {})} className="single-page-fixed-row-link"><i
                                className="fal fa-arrow-left"></i> <span>Back to home</span></a>
                        </div>
                        <section className="dark-bg no-padding">
                            <div className="hidden-info-wrap-bg">
                                <div className="bg-ser">
                                </div>
                                <div className="overlay"></div>
                            </div>
                            <div className="hidden-info-wrap">
                                <div className="hidden-info fl-wrap">
                                    <div className="hidden-info-title">Web Application Development</div>
                                    <div className="hidden-works-list fl-wrap">
                                        <div className="hidden-works-item  serv-works-item fl-wrap"
                                             data-bgscr="images/bg/long/1.jpg">
                                            <div className="hidden-works-item-text">
                                                <h3>Web Application Development</h3>
                                                <p>Atmiyo gives shape to your ideas. With the boom in technology, having just a website is not enough. To keep the web alive you need to have a software which runs through a browser - Web Application. Web applications are much more interactive than websites. Applications developed over the web helps to engage more users and for organizations who want to stream lime their digital processes, web applications is a great way to achieve desired results.</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">01.</span>
                                                <div className="serv-icon"><i className="fal fa-desktop"></i></div>
                                            </div>
                                        </div>
                                        <div className="hidden-works-item  serv-works-item fl-wrap"
                                             data-bgscr="images/bg/long/1.jpg">
                                            <div className="hidden-works-item-text">
                                                <h3>Elevate your business with user centered applications</h3>
                                                <p>Depending on the requirements of your business, we develop web applications on a range of technologies</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">03.</span>
                                                <div className="serv-icon"><i className="fab fa-desktop-alt"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="fl-wrap limit-box"></div>
                        </section>
                        <section className="dark-bg2 small-padding order-wrap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h3>Ready To order Your Project ?</h3>
                                    </div>
                                    <div className="col-md-4"><a href="contscts.html"
                                                                 className="btn flat-btn color-btn">Get In Touch</a>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="height-emulator fl-wrap"></div>
                </div>
            </React.Fragment>
        )
    }
}

export default compose<any>(NavBar, withRouter)(WebappDevelopment)

export class Utils {
    plugins = 'assets/js/plugins.js';
    scripts = 'assets/js/scripts.js';

    LoadScripts(): void{
        const scriptEl = document.createElement('script');
        scriptEl.src = this.scripts;
        scriptEl.id = 'script';
        const pluginEl = document.createElement('script');
        pluginEl.src = this.plugins;
        pluginEl.id = 'plugin';
        document.body.appendChild(pluginEl);
        document.body.appendChild(scriptEl)
    }

    UnloadScripts(): void {
        const scriptEl = document.getElementById('script');
        const pluginEl = document.getElementById('plugin');
        if (scriptEl !== undefined || scriptEl !== null) {
            scriptEl!.remove()
        }
        if (pluginEl !== undefined || pluginEl !== null) {
            pluginEl!.remove()
        }
    }
}
